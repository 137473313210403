import React from 'react';

import Layout from '../components/layout';
import withBg from '../helpers/withBg';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';
import TermsConditions from '../components/TermsConditions/TermsConditions';
import './styles.css';

const data = {
  title: 'Terms & Conditions',
  seoTitle: 'Terms & Conditions | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class termsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Terms &amp; Conditions</h1>
                <p>
                  The following terms and conditions (“Terms and Conditions”)
                  apply to all travel and travel related Services offered for
                  sale by Travelopod, Inc. and/or its agents, employees,
                  associates, affiliated companies, independent contractors, or
                  subcontractors (hereinafter referred to as “Travelopod”, “we,”
                  or “us”). Travelopod, Inc. is incorporated in state of
                  California. Our telephone number is 1-844-455-5444.
                </p>
                <p>
                  The terms “Service” or “Services” as used in these Terms and
                  Conditions encompass: travel planning and consultation,
                  charter or other airfare, passage on cruises, charters, river
                  boats, yachts, and other water vessels, ground transportation,
                  concierge services, hotel rooms, resorts, camps, or other
                  lodgings, tours, car rentals, leisure or adventure activities,
                  equipment rentals, expeditions, and any other travel or
                  travel-related products offered, sold, recommended, or
                  provided by Travelopod. The term “Trip” is defined as any
                  Service, or package of Services, offered. “Supplier” or
                  “Vendor” refers to any provider of the Services we offer.
                  “Itinerary” refers to the particularized schedule for a Trip.
                  References herein to “Traveler,” “you,” or “your” shall apply
                  to each and any of the following: a party participating in an
                  offered Trip and/or the party who purchases, or attempts to
                  purchase, a Trip for themselves and/or others.
                </p>
                <p>
                  <strong>
                    All bookings of Trips are also subject to the Terms and
                    Conditions of the supplier of the Service incorporated in a
                    Travelopod Trip.
                  </strong>{' '}
                  By placing an order with Travelopod, you agree to abide by all
                  the Terms and Conditions of the applicable Suppliers without
                  reservation, and to be bound by the limitations therein. If
                  the Supplier’s Terms and Conditions are ever in conflict with
                  the Terms and Conditions of Travelopod, Travelopod’s will
                  control all issues relating to the liabilities and
                  responsibilities of Travelopod.
                </p>
                <p>
                  Please read these Terms and Conditions carefully, ask us any
                  questions, and consult your attorney before you agree to be
                  bound by them. Traveler acknowledges that they have taken note
                  of these Terms and Conditions before making a booking and have
                  accepted the same by clicking on the “I Agree” box on the
                  bottom of the submission page. When Traveler is purchasing a
                  Trip for someone besides themselves, clicking on the “I Agree”
                  box verifies Traveler has informed all other Travelers in
                  their group of these Terms and Conditions and accepts them on
                  their behalf, warranting that the other Travelers in their
                  group, after consideration and with an opportunity to consult
                  legal counsel, also agree to be bound by these Terms and
                  Conditions. Without this acceptance, the processing of an
                  order or inquiry is not technically possible. Therefore, by
                  placing an order with Travelopod, you, and all Travelers in
                  your group (if applicable), agree to abide by these Terms and
                  Conditions without reservation and to be bound by the
                  limitations herein.
                </p>
                <h2>Table of Contents</h2>
                <TermsConditions />
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(termsConditions);
